<template>
    <div class="rag-sources">
      <ul>
        <li v-for="(source, index) in sources" :key="index">{{ index + 1 }}) {{ source }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      sources: Array
    }
  }
  </script>
  
  <style scoped>
  .rag-sources {
background-color: transparent;
border-radius: 5px;
height: 100%;
}
.rag-sources ul {
list-style: none;
padding: 0;
margin-top: 10px;
}
.rag-sources li {
margin-bottom: 5px;
}
</style>
  