<template>
  <div v-if="visible" class="modal-overlay" @click.self="cancel">
      <div class="modal">
        <button class="close-button" @click="cancel">X</button>
        <input 
          ref="nodeInput" 
          type="text" 
          v-model="nodeLabel" 
          placeholder="Node Contents" 
          class="modal-input" 
          @keyup.enter="submit"
          >
        <div class="modal-buttons">
          <button v-if="editing" class="delete-button" @click="deleteNode">Delete</button>
          <button class="save-button" @click="submit">Save</button>
        </div>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: Boolean,
      editing: Boolean,
      initialLabel: String,
      errorMessage: String
    },
    data() {
      return {
        nodeLabel: this.initialLabel, // Initialize nodeLabel with the initial value
      };
    },
    watch: {
      initialLabel(newLabel) {
        this.nodeLabel = newLabel; // Update nodeLabel when initialLabel changes
      },
      visible(newVal) {
      if (newVal) {
        // Add keydown listener when modal becomes visible
        this.addKeydownListener();
        this.$nextTick(() => {
          this.$refs.nodeInput.focus(); // Focus the input element
        });
      } else {
        // Remove keydown listener when modal is no longer visible
        this.removeKeydownListener();
      }

      if (!newVal && this.editing) {
        // Reset the label to the initial value if modal is closing and was editing
        this.nodeLabel = this.initialLabel;
      }
    },
    },
    methods: {
      addKeydownListener() {
      this.keydownListener = event => {
        if (event.key === "Delete") {
          this.deleteNode();
        }
        if (event.key === "Escape") { // Check for the ESC key
          this.cancel();
        }
      };
      window.addEventListener('keydown', this.keydownListener);
    },
    removeKeydownListener() {
      window.removeEventListener('keydown', this.keydownListener);
    },
      submit() {
      if (this.nodeLabel !== this.initialLabel) {
        this.$emit('submit', this.nodeLabel);
      } else {
        this.cancel(); // If the label hasn't changed, just close the modal
      }
    },
      deleteNode() {
        this.$emit('delete');
      },
      cancel() {
      this.removeKeydownListener(); // Remove keydown listener when modal is canceled
      this.$emit('cancel');
      // Reset nodeLabel to initialLabel on cancel to discard any unsaved changes
      this.nodeLabel = this.initialLabel;
    },
      beforeDestroy() {
      this.removeKeydownListener(); // Remove listener when component is destroyed
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  }
  
  .modal {
    background-color: black;
    border: 2px solid white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .close-button {
    background-color: red;
    color: white;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #f8b400; /* Yellow-orange border */
    color: grey;
    background-color: black;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .delete-button:hover {
  background-color: #d9534f; /* A darker shade of red for the hover state */
}
  .save-button {
    background-color: #f8b400; /* Yellow-orange background */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Adjust the hover states for buttons as needed */
  .save-button:hover {
  background-color: #e89c30; /* Darker yellow-orange for hover */
    }

    .error-message {
  color: red;
  margin-top: 10px;
}
  </style>
  