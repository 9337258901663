<template>
  <div class="app-container">
    <div class="left-side-container">
      <!-- Mode Toggle Buttons -->
      <div class="mode-toggle-buttons">
        <!-- Buttons are shown but disabled in 'Free' mode -->
        <button 
          :class="{ active: mode === 'Journal', inactive: mode !== 'Journal' || isFreeMode }"
          @click="handleModeClick('Journal')">
          Journal
        </button>
        <button 
          :class="{ active: mode === 'Collaborate', inactive: mode !== 'Collaborate' || isFreeMode }"
          @click="handleModeClick('Collaborate')">
          Collaborate
        </button>
      </div>
      <!-- Knowledge Graph Container -->
      <div class="knowledge-graph-container">
        <KnowledgeGraph
          ref="knowledgeGraph"
          :key="graphKey"
          :nodes="nodes"
          :edges="edges"
          @node-selected="nodeSelected"
          @update-node="handleUpdateNode"
          @delete-node="handleDeleteNode" 
          @update-edge="handleUpdateEdge"
          @delete-edge="handleDeleteEdge"
          @graph-updated="handleGraphUpdated"
        />
      </div>
      <!-- Split Rag Sources Container -->
      <div class="split-rag-sources-container">
        <!-- New Rag Sources for Notes -->
        <div class="notes-container">
          <rag-sources :sources="notes" />
        </div>
        <!-- Original Rag Sources -->
        <div class="rag-sources-container">
          <div class="scroll-content">
            <rag-sources :sources="ragSources" />
        </div>
        </div>
      </div>
    </div>
    <!-- Chat Container -->
    <div class="chat-container">
      <!-- Chat Area -->
      <div class="chat-area">
        <chat-message
          v-for="(msg, index) in messages"
          :key="index"
          :message="msg.text"
          :is-user="msg.isUser"
        />
      </div>
      <!-- Input Area -->
      <div class="input-area">
        <message-input
          :showPlayButton="showPlayButton"
          :playing="playing"
          @send="handleInput"
          @audio-recorded="handleInput"
          @start-playback="playAudio"
          @stop-playback="stopAudio"
        ></message-input>
      </div>
    </div>


<!-- Modal for Free Mode Notification -->
<div v-if="showModal" class="modal-overlay">
  <div class="modal-content">
    <button class="close-button" @click="closeModal">x</button>
    <h2>Unlock Unlimited Augmented Journaling!</h2>
    <p>
      <b>Sign In & Subscribe:</b><br>
      - Perfect Memory Retrieval <br>
      - Perfect Knowledge Graph Retrieval <br>
      - AI Assistant who understands it all <br>
      - Collaborate with others in Community Mode <br>
      - Set, Track, Learn, and Achieve your Goals! <br> 
      <b>Get Started today!</b>
    </p>
    <div v-if="isAuthenticated" class="button-container">
      <!-- Conditionally render the button to either subscribe or go to the customer portal -->
      <button class="subscribe-btn" @click="handleSubscribeButtonClick">
        {{ ['Journal', 'Collaborate'].includes(mode) ? 'Customer Portal' : 'Subscribe' }}
      </button>
      <!-- Sign Out Button -->
      <button class="sign-out-btn" @click="handleSignOut">Sign Out</button>
    </div>
    <div v-else>
      <div id="firebaseui-auth-container"></div>
    </div>
  <!-- Add a link to explain JournalAI -->
  <div class="info-link">
    <a href="/get-to-know-journalai" class="info-btn">Get to know JournalAI</a>
  </div>
    <div class="privacy-policy">
      <p><b>Privacy Policy:</b><br>
        At JournalAI, we value your privacy and are committed to a secure and reliable AI Augmented Journaling experience. We collect your email and journal entries only to manage your account and improve our services; this data is anonymized and then stored securely to ensure your privacy. Our business model relies solely on selling subscriptions, not on selling user data. We do not share your information with other users, advertisers, or third parties. Your personal information is used exclusively to enhance your journaling experience and maintain the integrity of our services. Thank you, and enjoy JournalAI: Augmented Journaling!
      </p>
    </div>
  </div>
</div>

</div>
</template>

<script>
import { ref, computed, watch, onMounted, nextTick, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import ChatMessage from '@/components/ChatMessage.vue';
import MessageInput from '@/components/MessageInput.vue';
import RagSources from '@/components/RagSources.vue';
import KnowledgeGraph from '@/components/KnowledgeGraph.vue';
import { auth, db, onAuthStateChanged } from '@/firebase';
import { v4 as uuidv4 } from 'uuid';
import { EmailAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { collection, addDoc, doc, onSnapshot } from 'firebase/firestore';

const priceId = 'price_1P5f8t2LxPYqMRTh6nZoUygj';

export default {
  components: {
    ChatMessage,
    MessageInput,
    RagSources,
    KnowledgeGraph
  },
  setup() {
    const route = useRoute();
    const modeQuery = ref(route.query.mode);
    const isFreeMode = computed(() => modeQuery.value === 'free');
    const mode = ref('free');
    const showModal = ref(false);
    const isAuthenticated = ref(false);
    const messages = ref([]);
    const ragSources = ref([]);
    const nodes = ref([]);
    const edges = ref([]);
    const notes = ref([]);
    const showPlayButton = ref(false);
    const playing = ref(false);
    const audioToPlay = ref(null);
    const tempUserId = ref(null);

    watch(
      () => route.query.mode,
      (newMode) => {
        if (newMode === 'free') {
          mode.value = 'free';
        } else if (newMode === 'full') {
          mode.value = 'Journal';
        } else {
          mode.value = 'free';
        }
      },
      { immediate: true }
    );

    watch(
      [isAuthenticated, mode],
      ([newIsAuthenticated, newMode]) => {
        if (newIsAuthenticated && !['Journal', 'Collaborate'].includes(newMode)) {
          showModal.value = true;
        } else {
          showModal.value = false;
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      onAuthStateChanged(auth, () => {
        isAuthenticated.value = !!auth.currentUser;
        console.log('Auth state changed. User:', auth.currentUser);
      });

      setViewportHeight();
      window.addEventListener('resize', setViewportHeight);
      window.addEventListener('beforeunload', resetContext);
      window.addEventListener('beforeunload', beforePageUnload);

      if (mode.value === 'free') {
        tempUserId.value = `free_user_${uuidv4()}`;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('beforeunload', resetContext);
      window.removeEventListener('beforeunload', beforePageUnload);
    });

    function setMode(newMode) {
      if (!isFreeMode.value) {
        mode.value = newMode;
      }
    }

    function beforePageUnload(event) {
      // Add any logic you want to execute before the page unloads
      // For example, saving user data or showing a confirmation dialog
      event.preventDefault(); // Cancel the event
      event.returnValue = ''; // Chrome requires returnValue to be set
    }

    function clearData() {
      messages.value = [];
      nodes.value = [];
      edges.value = [];
      notes.value = [];
      ragSources.value = [];
    }

    function handleSubscribeButtonClick() {
      if (mode.value === 'Journal' || mode.value === 'Collaborate') {
        window.open('https://billing.stripe.com/p/login/6oE6qB3mm2co5ag3cc', '_blank');
      } else {
        startSubscription();
      }
    }

    async function startSubscription() {
      console.log('Start subscription called');
      if (!auth) {
        console.error('User is not signed in');
        return;
      }
      const user = auth.currentUser;
      console.log(`User is signed in with UID: ${user.uid}`);
      const colRef = collection(db, 'customers', user.uid, 'checkout_sessions');
      try {
        const docRef = await addDoc(colRef, {
          price: priceId,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });
        console.log(`Document added to Firestore with ID: ${docRef.id}`);
        const docRefPath = doc(db, 'customers', user.uid, 'checkout_sessions', docRef.id);
        onSnapshot(docRefPath, (doc) => {
          if (doc.exists()) {
            const { error, url } = doc.data();
            if (error) {
              console.error(`An error occurred: ${error.message}`);
            } else if (url) {
              window.location.assign(url);
            }
          } else {
            console.error('Snapshot does not exist - this is unexpected as the document should exist once created');
          }
        });
      } catch (error) {
        console.error('Error adding document to Firestore:', error);
      }
    }

    async function handleModeClick(newMode) {
      if (isFreeMode.value) {
        showModal.value = true;
        await nextTick();
        console.log('Modal is shown. Initializing FirebaseUI...');
        nextTick(() => {
          initFirebaseUI();
        });
      } else {
        if (mode.value == newMode) {
          showModal.value = true;
        } else {
          clearData();
          setMode(newMode);
          await resetContext();
        }
      }
    }

    function initFirebaseUI() {
      const uiConfig = {
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
          }
        ],
        signInSuccessUrl: window.location.href,
      };
      const container = document.getElementById('firebaseui-auth-container');
      if (container) {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', uiConfig);
      } else {
        console.error('FirebaseUI container not found');
      }
    }

    function closeModal() {
      showModal.value = false;
    }

    function handleSignOut() {
      auth.signOut().then(() => {
        showModal.value = false;
        window.location.reload();
      }).catch(error => {
        console.error("Sign Out Failed", error);
      });
    }

    function setViewportHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    function getUserId() {
      if (mode.value === 'Journal') {
        return auth.currentUser.uid;
      } else if (mode.value === 'Collaborate') {
        return 'Community_Mode';
      } else if (mode.value === 'free') {
        return tempUserId.value;
      }
      return null;
    }

    function isIOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    function handleAudioPlayback() {
      if (isIOS()) {
        showPlayButton.value = true;
      } else {
        playAudio();
      }
    }

    function playAudio() {
      if (audioToPlay.value && !playing.value) {
        audioToPlay.value.play();
        playing.value = true;
        showPlayButton.value = false;
        audioToPlay.value.onended = () => {
          playing.value = false;
        };
      }
    }

    function stopAudio() {
      if (audioToPlay.value && playing.value) {
        audioToPlay.value.pause();
        audioToPlay.value.currentTime = 0;
        playing.value = false;
      }
    }

    async function resetContext() {
      const maxRetries = 3; // Maximum number of retries
      let attempt = 0; // Current attempt number
      let indexLoadSuccessful = false; // Flag to check if index load was successful

      // Function to attempt loading indexes with retries
      const loadIndexes = async () => {
        while (attempt < maxRetries) {
          try {
            const loadIndexesResponse = await axios.post('https://journal-ai-embeddings-bruceligao.replit.app/loadIndexes', {
              embedding_dim: 1536,
              userId: getUserId() // Pass the userId along with other data
            });

            if (loadIndexesResponse.status === 200 && loadIndexesResponse.data && loadIndexesResponse.data.message === 'Indexes loaded successfully') {
              console.log('Indexes loaded:', loadIndexesResponse.data.message);
              return true; // Successfully loaded indexes
            } else {
              throw new Error('Unexpected response loading indexes: ' + loadIndexesResponse.data.message);
            }
          } catch (error) {
            console.error('Attempt', attempt + 1, 'failed to load indexes:', error);
            attempt++;
            if (attempt < maxRetries) {
              await new Promise(resolve => setTimeout(resolve, 2000 * Math.pow(2, attempt))); // Exponential backoff
            }
          }
        }
        return false; // Failed to load indexes after retries
      };

      // Attempt to load indexes with retries
      indexLoadSuccessful = await loadIndexes();

      // Always attempt to reset context, regardless of index load success
      try {
        const resetContextResponse = await axios.post('https://journal-ai-application-bruceligao.replit.app/api/resetContext');

        if (resetContextResponse.status !== 200) {
          throw new Error('Unexpected response resetting context: ' + resetContextResponse.data.message);
        }
        console.log('Context reset:', resetContextResponse.data.message);
      } catch (error) {
        console.error('Error resetting context:', error);
        if (!indexLoadSuccessful) {
          throw new Error('Failed to load indexes and reset context');
        }
        throw error; // If resetting context fails, throw the error
      }

      // Return a message indicating what was successful
      if (!indexLoadSuccessful) {
        return { message: "Failed to load indexes, but context reset successfully" };
      }
      return { message: "Index Loaded and Context Reset successfully" };
    }

    async function handleInput(userInput, inputType) {
      ragSources.value = [];
      nodes.value = [];
      edges.value = [];
      notes.value = [];

      const userId = getUserId();

      if (inputType === 'audio') {
        const formData = new FormData();
        formData.append('audio', userInput, 'audio.mp3');

        try {
          const response = await axios.post('https://journal-ai-application-bruceligao.replit.app/api/speechToText', formData, {
            onUploadProgress: progressEvent => {
              console.log(`Upload Progress: ${Math.round((progressEvent.loaded * 100) / progressEvent.total)}%`);
            }
          });
          userInput = response.data.text;
        } catch (error) {
          console.error('Upload failed:', error);
          return;
        }
      }

      const now = new Date();
      const timestampString = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

      messages.value.push({
        timestamp: timestampString,
        text: userInput,
        isUser: true
      });

      nextTick(() => {
        scrollToBottom();
      });

      try {
        const response = await axios.post('https://journal-ai-application-bruceligao.replit.app/api/retrieval', {
          userId: userId,
          timestamp: timestampString,
          text: userInput
        });
        console.log('Response from retrieval:', response.data);

        ragSources.value = response.data.ragSources;
        nodes.value = response.data.nodes;
        edges.value = response.data.edges;
        notes.value = response.data.notes;
      } catch (error) {
        console.error('Error with retrieval:', error);
      }

      const oagPromise = axios.post('https://journal-ai-application-bruceligao.replit.app/api/oag');
      const ontologyPromise = axios.post('https://journal-ai-application-bruceligao.replit.app/api/generateOntology', { userId: userId });
      const notesPromise = axios.post('https://journal-ai-application-bruceligao.replit.app/api/generateNewNote', { assistantId: userId + "_assistant", timestamp: timestampString });

      oagPromise.then(oagResponse => {
        console.log('Response from oag:', oagResponse.data);
        messages.value.push({
          timestamp: oagResponse.data.lastMessage.timestamp,
          text: oagResponse.data.lastMessage.text,
          isUser: oagResponse.data.lastMessage.isUser
        });
        nextTick(() => {
          scrollToBottom();
        });
        if (inputType === 'audio' && oagResponse.data.lastMessage.text) {
          axios.post('https://journal-ai-application-bruceligao.replit.app/api/textToSpeech', {
            text: oagResponse.data.lastMessage.text,
            mode: mode.value
          }, {
            responseType: 'blob'
          }).then(audioResponse => {
            const audioUrl = URL.createObjectURL(audioResponse.data);
            let audio = new Audio(audioUrl);
            audio.playbackRate = 1.25;
            audioToPlay.value = audio;
            handleAudioPlayback();
          }).catch(audioError => {
            console.error('Error with audio conversion:', audioError);
          });
        }
      }).catch(error => {
        console.error('Error with oag:', error);
      });

      ontologyPromise.then(ontologyResponse => {
        console.log('Response from generateOntology:', ontologyResponse.data);
      }).catch(error => {
        console.error('Error with generateOntology:', error);
      });

      notesPromise.then(notesResponse => {
        console.log('Response from generateNewNote:', notesResponse.data);
      }).catch(error => {
        console.error('Error with generateNewNote:', error);
      });
    }

    function scrollToBottom() {
      const chatArea = document.querySelector(".chat-area");
      if (chatArea) {
        chatArea.scrollTop = chatArea.scrollHeight;
      }
    }

    return {
      mode,
      isFreeMode,
      showModal,
      isAuthenticated,
      messages,
      ragSources,
      nodes,
      edges,
      notes,
      showPlayButton,
      playing,
      audioToPlay,
      tempUserId,
      setMode,
      handleSubscribeButtonClick,
      handleModeClick,
      closeModal,
      handleSignOut,
      setViewportHeight,
      getUserId,
      isIOS,
      handleAudioPlayback,
      playAudio,
      stopAudio,
      resetContext,
      handleInput,
      scrollToBottom,
      beforePageUnload
    };
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Apply box-sizing universally */
}

html,
body {
  height: auto; /* Set to auto to allow content to dictate the body height */
  min-height: 100%; /* Ensure it's at least the height of the viewport */
}

.app-container {
  display: flex;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-height: 97vh;
  flex-direction: row;
}

.chat-container {
  flex-basis: 34%; /* Updated to take up the remaining 1/3 of the width */
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  overflow-y: auto; /* Allow scrolling */
  max-height: 100vh;
}

.chat-area {
  overflow-y: auto; /* Scrollbar appears if content overflows vertically */
  overflow-x: hidden; /* Prevents horizontal scrolling */
  flex-grow: 1; /* Allows chat area to expand to fill available space */
}

.input-area {
  flex-shrink: 0; /* Prevents input area from shrinking */
  background: #e6e6e6; /* Different background for input area */
  border-radius: 18px; /* Rounded borders for input area */
  overflow-y: hidden; /* Enable vertical scroll for the input area */
}

.message {
  padding: 12px 20px; /* Padding around messages */
  margin-bottom: 10px; /* Space between messages */
  border-radius: 18px; /* Rounded borders for messages */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Shadow for messages */
  background-color: #ffffff; /* White background for messages */
  border: 1px solid #e0e0e0; /* Border for messages */
  max-width: 80%;
  word-break: break-word; /* Ensures long words wrap and don't force horizontal scrolling */
}

.user-message {
  background-color: #0078D7; /* Blue background for user messages */
  color: white; /* White text for readability */
  margin-left: auto; /* Align to the right */
}

.rag-message {
  background-color: #f2f2f2; /* Background for RAG messages */
  margin-right: auto; /* Align to the left */
}

.left-side-container {
  flex-basis: 66%; /* Updated to take up 2/3 of the width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  overflow-y: auto; /* Allow scrolling */
  overflow-x: hidden; /* Prevent horizontal scroll if content overflows */
  max-height: 100vh;
  border-right: 1px solid #e0e0e0; /* Add border if desired */
}

.left-side-container > * {
  flex-shrink: 1;
}
.split-rag-sources-container {
    display: flex;
    justify-content: space-between;
  }

.rag-sources-container {
  display: flex;
  flex-direction: column; /* or row, depending on your layout */
  align-items: stretch; /* ensures that the children stretch to fill the container */
  width: 50%;
  height: 31vh;
  color: white; /* White text for readability */
  background-color: #0078D7; /* Blue background stays with the container */
}

.scroll-content {
  color: white; /* White text for readability */
  width: 100%; /* Ensures it fills the parent container */
  height: 100%; /* Ensures it fills the parent container */
  overflow-y: auto; /* Allow vertical scroll within this inner content */
  overflow-x: hidden; /* Prevent horizontal scroll if content overflows */
  word-wrap: break-word; /* Ensure long words do not cause overflow */
  white-space: pre-wrap; /* Preserve whitespace as necessary */
  background-color: #0078D7; /* Blue background stays with the container */
  padding: 0;
  margin: 0;
  min-height: 100%; /* Matches the parent container height */
}

.notes-container {
  display: flex;
  flex-direction: column; /* or row, depending on your layout */
  align-items: stretch; /* ensures that the children stretch to fill the container */
  width: 50%;
  height: 31vh;
  overflow-y: auto; /* Allow vertical scroll within the rag-sources container */
  overflow-x: hidden; /* Prevent horizontal scroll if content overflows */
  background-color: #ffffff; /* White background for contrast */
  word-wrap: break-word; /* Make sure long words do not cause overflow */
  white-space: pre-wrap; /* If your text contains whitespace that should be preserved */
}

.knowledge-graph-container {
  width: 100%; /* Full width of its container */
  height: 66vh; /* Adjust the height as necessary */
  overflow-y: hidden; 
  overflow-x: hidden;
  background-color: black; /* Set page background to black */
  flex-shrink: 0; /* Prevent the container from shrinking */
}

.mode-toggle-buttons {
  position: absolute; /* Positioning relative to the nearest positioned ancestor */
  top: 0; /* At the top of the container */
  left: 0; /* At the start of the container */
  z-index: 10; /* Ensure it's on top of other content */
  display: flex;
  flex-direction: column; /* This aligns the buttons vertically */
}

.mode-toggle-buttons button {
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for color and opacity */
}

.mode-toggle-buttons button.active {
  opacity: 1; /* Fully opaque for active */
}

.mode-toggle-buttons button.inactive {
  opacity: 0.5; /* Half opacity for inactive */
}

.mode-toggle-buttons button:nth-child(1).active {
  background: #0078D7; /* Active state for Journal */
}

.mode-toggle-buttons button:nth-child(1).inactive {
  background: #80b3ff; /* Lighter blue for inactive Journal */
}

.mode-toggle-buttons button:nth-child(2).active {
  background: #f0b300; /* Active state for Collaborate */
}

.mode-toggle-buttons button:nth-child(2).inactive {
  background: #ffd480; /* Lighter yellow/orange for inactive Collaborate */
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
}

.modal-content {
  background: #80b3ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
  max-width: 600px; /* Adjust width if necessary */
  width: 100%;
}

.modal-content h2 {
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.modal-content #firebaseui-auth-container {
  display: flex;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: column; /* Stacks children elements vertically */
  align-items: center; /* Centers the buttons horizontally */
}

.subscribe-btn {
  display: inline-block;
  background-color: #0078D7;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin: 0 auto; /* Center the button horizontally */
}

.subscribe-btn:hover {
  background-color: #005bb5;
}

/* Sign-Out Button Styles */
.sign-out-btn {
  display: inline-block;
  background-color: #ff4d4d; /* Red color to signify sign out */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 10px; /* Add some space between subscribe and sign out button */
}

.sign-out-btn:hover {
  background-color: #cc0000; /* Darker shade of red on hover */
}

.info-btn {
  display: block;
  text-align: center;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #0078D7;  /* Match your theme */
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.info-btn:hover {
  background-color: #005bb5;
}

/* Add these styles for the privacy policy */
.privacy-policy {
  margin-top: 20px;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;  /* Smaller font size */
  text-align: left;
  opacity: 0.8; /* Less prominent */
  color: #555; /* Lighter color */
}

@media (max-width: 600px) and (orientation: portrait) {
  .app-container {
  max-height: 88vh;
}
}

</style>
