<template>
  <div class="homepage-background">
    <button class="get-started-btn" @click="goToJournal">Get Started</button>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'; // Corrected import statement

export default {
  setup() {
    const router = useRouter();

    function goToJournal() {
      router.push({ path: '/journal' });  // Let the router handle mode determination
    }

    return { goToJournal };
  }
};
</script>

<style>
.homepage-background {
  background-image: url('@/assets/JournalAI.png');
  background-size: cover;
  background-position: center;
  height: 97vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.get-started-btn {
  position: absolute;
  top: 66%; /* Two-thirds down the page */
  padding: 10px 20px;
  font-size: 64px;
  color: #ffffff;
  background-color: #FA8334; /* Bright contrasting color */
  border: 2px solid #ffffff; /* White border for more contrast */
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition for interaction */
}

.get-started-btn:hover, .get-started-btn:focus {
  transform: scale(1.1); /* Enlarges the button on hover */
  background-color: #FB9937; /* Slightly lighter shade on hover */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(202, 138, 4, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(202, 138, 4, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(202, 138, 4, 0);
  }
}

.get-started-btn {
  animation: pulse 2s infinite;
}
</style>
