<template>
  <div class="chat-message" :class="{ 'user': isUser, 'ragchat': !isUser }" v-html="formattedMessage">
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    isUser: Boolean
  },
  computed: {
    formattedMessage() {
      return this.formatMarkdown(this.message);
    }
  },
  methods: {
    formatMarkdown(text) {
      const htmlText = text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold
        .replace(/### (.*?)(?=\n|$)/g, '<h3>$1</h3>') // Headers
        .replace(/\n/g, '<br>'); // New lines

      return htmlText;
    }
  }
}
</script>

<style scoped>
.chat-message {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}
.chat-message.user {
  background-color: #0078D7; /* Blue background */
  color: white; /* White text for readability */
  margin-right: auto; /* Align to the left */
  margin-left: 2vw;
  word-break: break-word; /* Ensures that words can be broken and wrap to the next line */
}
.chat-message.ragchat {
  background-color: #f0f0f0; /* Light grey background */
  margin-left: auto; /* Align to the right */
  margin-right: 2vw;  
}
</style>
