<template>
  <div>
    <div id="cy" ref="cyElement"></div>
    <NodeModal
      ref="nodeModal"
      :visible="modalVisible"
      :editing="editingNode"
      :initialLabel="selectedNodeLabel"
      :errorMessage="errorMessage" 
      @submit="handleModalSubmit"
      @delete="handleModalDelete"
      @cancel="handleModalCancel"
    />
    <RelationshipModal
    ref="relationshipModal"
    :visible="relationshipModalVisible"
    :editing="editingEdge"
    :initialLabel="selectedEdgeData ? selectedEdgeData.label : ''"
    @submit="handleRelationshipModalSubmit"
    @delete="handleRelationshipModalDelete"
    @cancel="handleRelationshipModalCancel"
  />
  </div>
</template>

<script>
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import NodeModal from './NodeModal.vue';
import RelationshipModal from './RelationshipModal.vue';

cytoscape.use(dagre); // Register the dagre layout with Cytoscape.js

export default {
  name: 'KnowledgeGraph',
  props: {
    nodes: Array,
    edges: Array,
    creatingRelationship: Boolean // Accept the prop
  },
  components: {
    NodeModal,
    RelationshipModal
  },
  data() {
    return {
      cy: null, // Store the cytoscape instance
      modalVisible: false,
      editingNode: false,
      selectedNodeLabel: '',
      selectedNodeId: null,
      newNodePosition: null, // Add this line
      relationshipModalVisible: false,
      editingEdge: false,
      selectedEdgeData: null,
      errorMessage: '', // Add this to hold the error message
      nodeMoved: false,
      dragListenerAttached: false,
    };
  },
  mounted() {
    this.initGraph(); // Call this synchronously if you're sure `#cy` is mounted
  },
  methods: {
    initGraph() {
    this.cy = cytoscape({
      container: this.$refs.cyElement,
      elements: this.nodes.map(node => ({
      group: 'nodes',
      data: {
        ...node.data,
        position: node.data.position // Include position in the data object
      }
    })).concat(this.edges),
    style: [ // Add your style configuration here
        {
          selector: 'node',
          style: {
            'background-color': ele => ele.data('color'), // Use the color field from node data
            'shape': ele => ele.data('shape'), // Use the shape field from node data
            'label': 'data(label)',
            'color': 'white', // White text color for the contents
            'text-valign': 'center',
            'text-halign': 'center',
            'font-size': function(ele) {
              return 14 + 3 * ele.degree(); // Increase size based on degree
            },            
            'width': function(ele) {
              return 20 + 20 * ele.degree(); // Increase size based on degree
            },
            'height': function(ele) {
              return 20 + 20 * ele.degree(); // Same for height
            },
            'z-index': function(ele) {
              return ele.degree(); // Use the node's degree for z-index
            }
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 2,
            'line-color': '#ccc', // Edge color, choose a color that works with the black background
            'target-arrow-color': '#ccc', // Arrow color
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            'label': 'data(label)', // Display the label stored in the edge's data
            'color': 'white', // Text color for the edge labels
            'text-background-opacity': 1, // Optional: For better readability
            'font-size': '20px', // Adjust font size as needed
          }
        }
      ],
      layout: {
        name: 'preset', // Use 'preset' to use the positions you provided
        fit: true, // Whether to fit the viewport to the graph
        padding: 0 // Padding around the graph
      },
      wheelSensitivity: 0.1, // Adjust this value as needed (default is 1)
    });
    this.setupEventListeners();
    },
    setupEventListeners() {
      this.cy.on('dblclick', 'node', (evt) => {
        const node = evt.target;
        this.selectedNodeId = node.id();
        this.selectedNodeLabel = node.data('label');
        this.editingNode = true;
        this.modalVisible = true;
      });

      this.cy.on('taphold', 'node', (evt) => {
        const node = evt.target;
        this.selectedNodeId = node.id();
        this.selectedNodeLabel = node.data('label');
        this.editingNode = true;
        this.modalVisible = true;
      });

      this.cy.on('doubletap', 'node', (evt) => {
        const node = evt.target;
        this.selectedNodeId = node.id();
        this.selectedNodeLabel = node.data('label');
        this.editingNode = true;
        this.modalVisible = true;
      });

      this.cy.on('tap', 'node', (evt) => {
      const nodeId = evt.target.id();
      this.nodeClicked(nodeId);
      });

      this.cy.on('dblclick', 'edge', (evt) => {
      const edge = evt.target;
      this.edgeClicked(edge);
      });

      // 'taphold' as an alternative for double-click on touch devices for edges
      this.cy.on('taphold', 'edge', (evt) => {
        const edge = evt.target;
        this.edgeClicked(edge);
      });

      this.cy.on('doubletap', 'edge', (evt) => {
        const edge = evt.target;
        this.edgeClicked(edge);
      });
      this.attachDragListener(); // Attach drag listener
},
    attachDragListener() {
        if (!this.dragListenerAttached) {
          this.cy.on('dragfree', 'node', this.handleNodeDrag);
          this.dragListenerAttached = true;
        }
      },

      detachDragListener() {
        if (this.dragListenerAttached) {
          this.cy.off('dragfree', 'node', this.handleNodeDrag);
          this.dragListenerAttached = false;
        }
      },
      handleNodeDrag: function() {
        if (!this.nodeMoved) {
          this.nodeMoved = true;
          this.$emit('graph-updated');
          this.detachDragListener(); // Detach the listener after the first drag
        }
      },
      resetNodeMoved() {
        this.nodeMoved = false;
        this.attachDragListener();
      },
    updateGraph(newNodes, newEdges) {
        // Add new nodes
        newNodes.forEach(node => {
        const existingNode = this.cy.getElementById(node.data.id);
        if (existingNode.length === 0) {
          this.cy.add({
            group: 'nodes',
            data: node.data,
            position: { x: node.data.position.x, y: node.data.position.y } // make sure positions are set
          });
        }
      });

      // Add new edges
      newEdges.forEach(edge => {
        if (this.cy.getElementById(edge.data.id).length === 0) {
          this.cy.add({ group: 'edges', data: edge.data });
        }
      });

      // Remove nodes not present in newNodes
      this.cy.nodes().forEach(node => {
        if (!newNodes.some(newNode => newNode.data.id === node.id())) {
          this.cy.remove(node);
        }
      });

      // Remove edges not present in newEdges
      this.cy.edges().forEach(edge => {
        if (!newEdges.some(newEdge => newEdge.data.id === edge.id())) {
          this.cy.remove(edge);
        }
      });

      // After updating the graph
      this.cy.fit(this.cy.elements(), 0); // Adjust the padding as needed

    this.$emit('graph-updated');
    },
    handleModalSubmit(newLabel) {
        // The check for initial and new label equality is removed, assuming submit is only called on change
        this.$emit('update-node', { newLabel: newLabel, id: this.selectedNodeId });
      },
      updatedNode() {
        this.modalVisible = false;
        this.selectedNodeLabel = '';
        this.selectedNodeId = null;
        this.editingNode = false;
          },
    setErrorMessage(message) {
        this.errorMessage = message; // Update the error message state
      },
    handleModalDelete() {
      if (this.editingNode) {
        const node = this.cy.getElementById(this.selectedNodeId);
        this.cy.remove(node); // This also removes edges connected to the node
        // Emit an event to update the parent component's data
        this.$emit('delete-node', this.selectedNodeId);
      }
      this.modalVisible = false;
      this.selectedNodeLabel = '';
      this.selectedNodeId = null;
      this.editingNode = false;
    },
    handleModalCancel() {
      this.modalVisible = false;
      this.errorMessage = ''; // Update the error message state
    },
    nodeClicked(nodeId) {
      this.$emit('node-selected', nodeId); // Always emit this event when a node is clicked
  },
  edgeClicked(edge) {
    this.selectedEdgeData = {
      id: edge.id(),
      label: edge.data('label'),
      source: edge.data('source'),
      target: edge.data('target'),
    };
    this.editingEdge = true;
    this.relationshipModalVisible = true;
  },
  handleRelationshipModalSubmit(newLabel) {
    if (this.editingEdge) {
      const edge = this.cy.getElementById(this.selectedEdgeData.id);
      edge.data('label', newLabel);
      // Update your data model here if necessary
      this.$emit('update-edge', { id: this.selectedEdgeData.id, label: newLabel });
    }
    this.relationshipModalVisible = false;
    this.editingEdge = false;
  },
  handleRelationshipModalDelete() {
    if (this.editingEdge) {
      const edge = this.cy.getElementById(this.selectedEdgeData.id);
      this.cy.remove(edge);
      // Update your data model here if necessary
      this.$emit('delete-edge', this.selectedEdgeData.id);
    }
    this.relationshipModalVisible = false;
    this.editingEdge = false;
  },
  handleRelationshipModalCancel() {
    this.relationshipModalVisible = false;
  },
  },
  watch: {
  nodes(newNodes) {
    this.updateGraph(newNodes, this.edges);
    console.log('Updated nodes:', newNodes); // Check the updated nodes
  },
  edges: {
    handler(newEdges) {
      this.updateGraph(this.nodes, newEdges); // Call updateGraph method when edges are updated
      console.log('Updated edges:', newEdges); // Check the updated edges
    },
    deep: true // Watch for deep changes within the edges array
  }
  }
};
</script>

<style>
#cy {
    display: block; /* Block-level element */
    position: relative; /* Positioned relative to its normal position */
    width: 100%; /* Use 100% width of its container */
    height: 66vh; /* Adjust the height to 70% of the viewport height */
    margin: 0 auto; /* Centered horizontally */
    overflow: visible; /* Hide any overflow */
}

@media (max-width: 768px) {
  #cy {
    width: 90%; /* Use 100% width of its container */
}}
</style>


