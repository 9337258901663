<template>
  <div v-if="visible" class="modal-overlay" @click.self="cancel">
      <div class="modal">
        <button class="close-button" @click="cancel">X</button>
        <input 
          ref="relationshipInput"
          type="text"
          v-model="relationshipLabel"
          placeholder="Relationship Type"
          class="modal-input"
          @keyup.enter="submit"
          >
        <div class="modal-buttons">
          <button v-if="editing" class="delete-button" @click="deleteRelationship">Delete</button>
          <button class="save-button" @click="submit">Save</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: Boolean,
      editing: Boolean,
      initialLabel: String
    },
    data() {
      return {
        relationshipLabel: this.initialLabel // Initialize relationshipLabel with the initial value
      };
    },
    watch: {
      initialLabel(newLabel) {
        this.relationshipLabel = newLabel; // Update relationshipLabel when initialLabel changes
      },
      visible(newVal) {
      if (newVal) {
        this.relationshipLabel = this.initialLabel; // Reset to initialLabel when modal becomes visible
        this.addKeydownListener();
        this.$nextTick(() => {
          this.$refs.relationshipInput.focus(); // Focus the input element
        });
      } else {
        this.removeKeydownListener();
      }

      if (!newVal && this.editing) {
        this.relationshipLabel = this.initialLabel;
      }
    }
    },
    methods: {
      addKeydownListener() {
      this.keydownListener = event => {
        if (event.key === "Delete") {
          this.deleteRelationship();
        }
        if (event.key === "Escape") { // Check for the ESC key
          this.cancel();
        }
      };
      window.addEventListener('keydown', this.keydownListener);
    },
    removeKeydownListener() {
      window.removeEventListener('keydown', this.keydownListener);
    },
      submit() {
        this.$emit('submit', this.relationshipLabel);
      },
      deleteRelationship() {
        this.$emit('delete');
      },
      cancel() {
        this.removeKeydownListener();
        this.$emit('cancel');
        // Reset relationshipLabel to initialLabel on cancel to discard any unsaved changes
        this.relationshipLabel = this.initialLabel;
        },
      beforeDestroy() {
        this.removeKeydownListener();
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  }
  
  .modal {
    background-color: black;
    border: 2px solid white;
    padding: 20px;
    border-radius: 10px;
    position: relative;
  }
  
  .close-button {
    background-color: red;
    color: white;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #f8b400; /* Yellow-orange border */
    color: grey;
    background-color: black;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .delete-button:hover {
  background-color: #d9534f; /* A darker shade of red for the hover state */
}
  .save-button {
    background-color: #f8b400; /* Yellow-orange background */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Adjust the hover states for buttons as needed */
  .save-button:hover {
  background-color: #e89c30; /* Darker yellow-orange for hover */
    }
  </style>
  