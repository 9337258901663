import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from './firebase'; // Directly import auth

let appInitialized = false;

auth.onAuthStateChanged(() => {
  // Initialize the Vue app after confirming the Firebase auth state is ready
  if (!appInitialized) {
    const app = createApp(App);
    app.use(router);
    app.mount('#app');
    appInitialized = true; // Prevent the app from reinitializing on auth state changes
  }
});
