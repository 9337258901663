import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import JournalApp from '../views/JournalApp.vue';
import { auth, db } from '@/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import GetToKnowJournalAI from '@/views/GetToKnowJournalAI.vue';

const routes = [
    { 
        path: '/', 
        component: HomePage,
    },
    { 
        path: '/journal', 
        component: JournalApp,
    },
    { 
      path: '/get-to-know-journalai', 
      component: GetToKnowJournalAI,
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const user = auth.currentUser;
  const isAuthenticated = !!user;
  let isSubscribed = false;

  if (isAuthenticated) {
    const subsCollectionRef = collection(db, `customers/${user.uid}/subscriptions`);
    const activeSubQuery = query(subsCollectionRef, where('status', '==', 'active'));
    const querySnapshot = await getDocs(activeSubQuery);
    isSubscribed = !querySnapshot.empty;
  }

  console.log('User authenticated:', isAuthenticated);
  console.log('User subscribed:', isSubscribed);
  console.log('Navigating to:', to.path);

  if (to.path === '/') {
    if (isAuthenticated && isSubscribed) {
      next({ path: '/journal', query: { mode: 'full' }});
    } else {
      next();
    }
  } else if (to.path === '/journal') {
    if (isAuthenticated && isSubscribed) {
      if (to.query.mode !== 'full') {
        next({ path: '/journal', query: { mode: 'full' }});
      } else {
        next();
      }
    } else {
      if (to.query.mode !== 'free') {
        next({ path: '/journal', query: { mode: 'free' }});
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
