<template>
  <div class="message-input">
    <textarea
      v-model="userInput"
      @keydown.enter="handleEnter"
      placeholder="Type a message..."
    />
    <div class="button-group">
      <button @click="sendMessage" class="send-button">Send</button>
      <button @click="toggleVoiceInput" class="microphone-button">
        <img :src="iconPath" alt="Voice Input"/>
      </button>
    </div>
  </div>
</template>

<script>
import RecordRTC from 'recordrtc';

export default {
  props: {
    showPlayButton: {
      type: Boolean,
      default: false
    },
    playing: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      userInput: '',
      recording: false,
      recorder: null,
      stream: null,
    };
  },
  methods: {
    sendMessage() {
      if (this.userInput.trim() !== '') {
        this.$emit('send', this.userInput.trim(), 'text');
        this.userInput = '';
      }
    },
    handleEnter(event) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
    toggleVoiceInput() {
      if (this.recording) {
        this.stopRecording();
      } else if (this.playing) {
        this.$emit('stop-playback');
      } else if (this.showPlayButton) {
        this.$emit('start-playback');
      } else {
        this.startRecording();
      }
    },
    async startRecording() {
      if (!navigator.mediaDevices) {
        alert("Media Devices API not supported in your browser.");
        this.recording = false; // Reset recording state on failure
        return;
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.stream = stream; // Store the stream for later use in stopping it
        this.recorder = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: 1,
          desiredSampRate: 16000
        });
        this.recorder.startRecording();
        this.recording = true;
      } catch (err) {
        console.error('Could not start recorder:', err);
        this.recording = false; // Ensure recording state is false on failure
      }
    },
    stopRecording() {
      this.recorder.stopRecording(() => {
      const audioBlob = this.recorder.getBlob(); // Get the recorded blob
      this.$emit('audio-recorded', audioBlob, 'audio');
      console.log('Recording finished and passed to parent component');

      // Properly stop the media stream
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null; // Clear the stream reference

      this.recorder = null; // Clear the recorder after stopping
      this.recording = false; // Reset recording state
    });
  },
  },
  watch: {
    userInput() {
      this.$nextTick(() => {
        const textarea = this.$el.querySelector('textarea');
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    }
  },
  computed: {
    iconPath() {
      if (this.recording) {
        return require('@/assets/recording_microphone_icon.png');
      } else if (this.playing) {
        return require('@/assets/stop_button.png');
      } else if (this.showPlayButton) {
        return require('@/assets/play_button.png');
      } else {
        return require('@/assets/microphone_icon.png');
      }
    }
  }
}
</script>

<style scoped>
.message-input {
  display: flex;
  align-items: flex-end;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.message-input textarea {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  resize: none;
  overflow: hidden;
  min-height: 50px;
  line-height: 1.5;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}

.message-input button, .microphone-button {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 5px;
  width: 40px;
  height: 40px;
}

.microphone-button img {
  width: 20px;
  height: 20px;
}

/* Mobile styles */
@media (max-width: 600px) {
  .message-input {
    padding: 5px;
    flex-wrap: nowrap;
  }

  .message-input textarea {
    padding: 5px;
    min-height: 40px;
  }

  .button-group {
    margin-left: 5px;
  }

  .send-button, .microphone-button {
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-top: 2px;
  }

  .microphone-button img {
    width: 15px;
    height: 15px;
  }
}
</style>


